export function getAttributionChannel({
  utmSource = '',
  utmTerm = '',
  utmCampaign = '',
  referrerDomain,
}: {
  utmSource: string | null
  utmTerm: string | null
  utmCampaign: string | null
  referrerDomain: string
}) {
  // Normalize everything to lowercase for case-insensitive checks
  const source = utmSource?.toLowerCase() ?? ''
  const term = utmTerm?.toLowerCase() ?? ''
  const campaign = utmCampaign?.toLowerCase() ?? ''
  const referrer = referrerDomain?.toLowerCase() ?? ''

  // 1) affiliates_email
  if (
    (source === 'impact' &&
      [
        '3041859',
        '59530',
        '2561341',
        '361346',
        '2191982',
        '11874',
        '2240581',
      ].includes(term)) ||
    source === 'transunion'
  ) {
    return 'affiliates_email'
  }

  // 2) affiliate_bof
  if (source === 'impact' && term !== '3198679') {
    return 'affiliate_bof'
  }

  // 3) non_brand_search
  //   - source is google/adwords/bing
  //   - campaign does NOT start with "nb-wt"
  //   - and campaign meets any "non-brand" criteria
  if (
    (source === 'google' || source === 'adwords' || source === 'bing') &&
    !campaign.startsWith('nb-wt') &&
    (campaign.startsWith('nb-') ||
      /se/i.test(campaign) ||
      /cs/i.test(campaign) ||
      campaign.includes('general') ||
      campaign.includes('exact') ||
      campaign.includes('non-brand') ||
      campaign.includes('conquest') ||
      campaign.includes('convenience') ||
      campaign.includes('generic') ||
      campaign.includes('no-exam') ||
      campaign.includes('online') ||
      campaign.includes('term-life-insurance') ||
      campaign.includes('{campaign'))
  ) {
    return 'non_brand_search'
  }

  // 4) brand_search
  //   - source is google/adwords/bing
  //   - and campaign matches any "brand" pattern
  if (
    (source === 'google' || source === 'adwords' || source === 'bing') &&
    (campaign.startsWith('b-') ||
      campaign.includes('branded') ||
      campaign.includes('_brand') ||
      (campaign.includes('_brand_') && source === 'bing'))
  ) {
    return 'brand_search'
  }

  // 5) lifecycle
  //   - source is one of "customerio", "customer.io", "iterable_c"
  //   - or referrer includes "mail."
  if (
    ['customerio', 'customer.io', 'iterable_c'].includes(source) ||
    referrer.includes('mail.')
  ) {
    return 'lifecycle'
  }

  // 6) organic_search
  //   - referrer includes google, bing, yahoo, duckduckgo
  //   - or source = "seo"
  if (
    referrer.includes('google.') ||
    referrer.includes('bing.') ||
    referrer.includes('yahoo.') ||
    referrer.includes('duckduckgo.') ||
    source === 'seo'
  ) {
    return 'organic_search'
  }

  // 7) Fallback value
  return 'social_user'
}
